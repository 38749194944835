var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '域名编辑' : '域名创建',"visible":_vm.visible,"width":800},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"disabled":_vm.isUpdate,"max-length":128,"placeholder":"不要以 http:// 或 https:// 开头"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"prop":"groups"}},[_c('group-select',{attrs:{"source-type":"domain"},model:{value:(_vm.form.groups),callback:function ($$v) {_vm.$set(_vm.form, "groups", $$v)},expression:"form.groups"}}),_c('question-icon',{attrs:{"slot":"label","title":"所属群组","description":"用于分组查询及告警配置"},slot:"label"})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }